import React, {useState, useEffect, useRef} from 'react';
import './EditProfile.scss';

import DefaultProfilePicture from '@backgrounds/cerebro_default_pp.png';
import {getMe, editProfile} from '@repository/UserRepository';
import {getUniversities} from '@repository/UniversityRepository';

import Select from '@components/Select';

function EditProfile() {
  const profilePicture = useRef(null);
  const [universities, setUniversities] = useState([]);
  const [user, setUser] = useState(null);
  const [inputs, setInputs] = useState({
    email: "",
    fullname: "",
    gender: "",
    profile_picture: null,
    profile_picture_url: null,
    university: "",
    university_id: "",
    username: "",
    university_name: null,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false);
  const [isLoadData, setIsLoadData] = useState(false);
  const [isLoadUniversities, setIsLoadUniversities] = useState(false);
  const [hasProfilePictureChanged, setHasProfilePictureChanged] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    handleGetUser();
  }, []);

  useEffect(() => {
    if (inputs.university_id !== "others") {
      setInputs((currentValue) => (
        {
          ...currentValue,
          university_name: null,
        }
      ));
    }
  }, [inputs.university_id]);

  useEffect(() => {
    handleGetUniversities();
  }, [searchName]);

  const handleGetUser = () => {
    setIsLoadData(true);
    getMe()
      .then((res) => {
        setIsLoadData(false);
        setUser(res.data);
        let universityId = res.data.university._id;
        let university = universities.find((mUniversity) => mUniversity.value === universityId);
        setInputs({
          ...res.data,
          university_id: res.data.university._id,
          university: res.data.university._id === "others" ? "Others" : res.data.university.name,
          university_name: res.data.university.name,
        });
      })
      .catch((err) => {
        setIsLoadData(false);
        console.log(err.response);
      });
  }

  const handleGetUniversities = () => {
    if (searchName.length >= 3) {
      setIsLoadUniversities(true);
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(function () {
        if (searchName.length < 3) {
          setUniversities([]);
          setIsLoadUniversities(false);
        } else {
          getUniversities(searchName)
            .then((res) => {
              let _universities = res.data.data.map((university) => ({
                label: university.name,
                value: university._id
              }));
              setUniversities(_universities);
            })
            .catch((err) => {
              console.log(err.response);
              setUniversities([]);
            })
            .finally(() => {
              setIsLoadUniversities(false);
            });
        }
      }, 1500),
    );
  }

  const handleOnInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleProfilePictureChanged = (e) => {
    setErrors({});
    let file = e.target.files[0];

    if (!file) {
      setInputs((currentValue) => (
        {
          ...currentValue,
          profile_picture: null,
          profile_picture_url: null,
        }
      ));
      return;
    }

    if (file.size > 2048000) {
      setErrors({
        profile_picture: ['Maximum profile picture size is 2MB'],
      })
      return;
    }

    console.log(file.size);

    let reader = new FileReader();
    reader.onloadend = () => {
      setInputs((currentValue) => (
        {
          ...currentValue,
          profile_picture: file,
          profile_picture_url: reader.result,
        }
      ));
    }
    reader.readAsDataURL(file);
  }

  const hasValidInputs = () => {
    if (
      !inputs.fullname ||
      !inputs.username ||
      !inputs.gender ||
      !inputs.university_id
    ) {
      return false;
    }
    if (inputs.university_id === 'others' && !inputs.university_name) {
      return false;
    }
    return true;
  }
  
  const handleOnEditProfile = () => {
    setIsLoading(true);
    setIsShowSuccessAlert(false);
    setErrors({});

    let formData = new FormData();

    for (let key in inputs) {
      if (inputs[key] !== null) {
        if (key == "profile_picture") {
          if (inputs[key] !== user.profile_picture) {
            formData.append(key, inputs[key]);
          }
        } else {
          formData.append(key, inputs[key]);
        }
      }
    }

    editProfile(formData)
      .then((res) => {
        setIsShowSuccessAlert(true);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setErrors(err.response.data.errors);
        }
        setIsLoading(false);
        console.log(err.response);
      });
  }

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <div id="edit-profile" className="container py-5 d-flex flex-column flex-grow-1">
      <input
        type="file"
        ref={profilePicture}
        onChange={handleProfilePictureChanged}
        accept="image/*"
        className="d-none"
      />
      {isLoadData ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px m-auto" />
      ) : (
        <div className="row">
          <div className="col-lg-3 col-md-4 d-md-flex d-none flex-column">
            <div
              className={`position-relative w-100 d-flex mb-3 image-desktop ${inputs.profile_picture_url ? 'bg-pure-white' : 'bg-smoke-gray'}`}
              style={{
                backgroundImage: `url(${inputs.profile_picture_url ? inputs.profile_picture_url : DefaultProfilePicture})`
              }}>
              <div
                className="mdi mdi-camera mdi-24px text-pure-white rounded-circle d-flex align-items-center justify-content-center icon-camera cursor-pointer"
                onClick={() => profilePicture.current.click()}
              />
            </div>
            <div className="asap-caption text-steel-gray">
              Profile Picture (Max: <b>2MB</b>, Format: <b>JPG, JPEG, PNG</b>)
            </div>
            {errors.profile_picture && (
              <div className="asap-caption-1 text-apple-red mb-3">{errors.profile_picture[0]}</div>
            )}
          </div>
          <div className="col-lg-9 col-md-8">
            {isShowSuccessAlert && (
              <div className="asap-body text-philadelphia-blue py-2 px-3 alert-success mb-3">
                Changes saved successfully.
              </div>
            )}
            <div className="dosis-title-1 mb-3 text-md-left text-center">Edit Profile</div>
            <div className="d-md-none d-flex flex-column align-items-center mb-3 image-mobile">
              <div
                className="position-relative d-flex bg-smoke-gray mb-3 image"
                style={{
                  backgroundImage: `url(${inputs.profile_picture_url ? inputs.profile_picture_url : DefaultProfilePicture})`
                }}>
                <div
                  className="mdi mdi-camera mdi-24px text-pure-white rounded-circle d-flex align-items-center justify-content-center icon-camera cursor-pointer"
                  onClick={() => profilePicture.current.click()}
                />
              </div>
              <div className="asap-caption text-steel-gray text-center description">
                Profile Picture (Max: <b>2MB</b>, Format: <b>JPG, JPEG, PNG</b>)
              </div>
              {errors.profile_picture && (
                <div className="asap-caption-1 text-apple-red mb-3">{errors.profile_picture[0]}</div>
              )}
            </div>
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className={`form-group d-flex flex-column-reverse ${errors.username ? 'mb-1' : 'mb-3'}`}>
                  <input
                    id="username"
                    type="text"
                    className="form-control"
                    placeholder="Enter your unique username here"
                    name="username"
                    onChange={handleOnInputsChanged}
                    value={inputs.username}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                        handleOnEditProfile();
                      }
                    }}
                    required
                  />
                  <label htmlFor='username' className='input-label'>
                    Username
                  </label>
                </div>
                {errors.username && (
                  <div className="asap-caption-1 text-apple-red mb-3">{errors.username[0]}</div>
                )}
              </div>
              <div className="col-lg-6">
                <div className={`form-group d-flex flex-column-reverse ${errors.fullname ? 'mb-1' : 'mb-3'}`}>
                  <input
                    id="fullname"
                    className="form-control"
                    placeholder="e.g. John Smith"
                    name="fullname"
                    onChange={handleOnInputsChanged}
                    value={inputs.fullname}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                        handleOnEditProfile();
                      }
                    }}
                    required
                  />
                  <label htmlFor='fullname' className='input-label'>
                    Full Name
                  </label>
                </div>
                {errors.fullname && (
                  <div className="asap-caption-1 text-apple-red mb-3">{errors.fullname[0]}</div>
                )}
              </div>
              <div className="col-lg-6">
                <div className={'form-group d-flex flex-column-reverse mb-3 position-relative'}>
                  <select className="form-control" disabled>
                    <option>{inputs.country ? inputs.country.name : "Select Your Country"}</option>
                  </select>
                  <label className='input-label'>
                    Country of Origin
                  </label>
                  <i className="mdi mdi-menu-down select-icon-drop-down" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`form-group d-flex flex-column-reverse position-relative ${errors.gender ? 'mb-1' : 'mb-3'}`}>
                  <Select
                    placeholder="Select Your Gender"
                    className="select"
                    options={[
                      {
                        label: "Male",
                        value: "male",
                      },
                      {
                        label: "Female",
                        value: "female",
                      },

                    ]}
                    onChange={(data) => {
                      setInputs((currentValue) => (
                        {
                          ...currentValue,
                          gender: data.value,
                        }
                      ))
                    }}
                    value={{
                      label: capitalizeFirstLetter(inputs.gender),
                      value: inputs.gender,
                    }}
                  />
                  <label className='input-label'>
                    Gender
                  </label>
                </div>
                {errors.gender && (
                  <div className="asap-caption-1 text-apple-red mb-3">{errors.gender[0]}</div>
                )}
              </div>
              <div className="col-lg-6">
                <div className={`form-group d-flex flex-column-reverse position-relative ${errors.university_id ? 'mb-1' : 'mb-3'}`}>
                  <Select
                    placeholder="Select Your University"
                    className="select"
                    options={universities}
                    onChange={(data) => {
                      setInputs((currentValue) => (
                        {
                          ...currentValue,
                          university: data.label,
                          university_id: data.value,
                        }
                      ))
                    }}
                    noOptionsMessage={() => searchName.length >= 3 ? 'No options' : 'Enter your university name'}
                    onInputChange={(text) => setSearchName(text)}
                    value={{
                      label: inputs.university,
                      value: inputs.university_id,
                    }}
                    isLoading={isLoadUniversities}
                    filterOption={(candidate, input) => {
                      return true || candidate.value === "others";
                    }}
                  />
                  <label className='input-label'>
                    University/College
                  </label>
                </div>
                {errors.university_id && (
                  <div className="asap-caption-1 text-apple-red mb-3">{errors.university_id[0]}</div>
                )}
              </div>
              {inputs.university_id === 'others' && (
                <div className="col-lg-6">
                  <div className={`form-group d-flex flex-column-reverse ${errors.university_name ? 'mb-1' : 'mb-3'}`}>
                    <input
                      id="university_name"
                      className="form-control"
                      placeholder="Enter your university here"
                      name="university_name"
                      onChange={handleOnInputsChanged}
                      value={inputs.university_name}
                      onKeyPress={event => {
                        if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                          handleOnEditProfile();
                        }
                      }}
                      required
                    />
                    <label htmlFor='university_name' className='input-label'>
                      Other University
                    </label>
                  </div>
                  {errors.university_name && (
                    <div className="asap-caption-1 text-apple-red mb-3">{errors.university_name[0]}</div>
                  )}
                </div>
                // <div className="col-lg-6 mb-3">
                //   <div className={`form-group d-flex flex-column-reverse position-relative ${errors.university_name && 'mb-1'}`}>
                //     <input
                //       id="university_name"
                //       type="text"
                //       name="university_name"
                //       className="form-control"
                //       placeholder="Enter your university here"
                //       onChange={handleOnInputsChanged}
                //       value={inputs.university_name}
                //       onKeyPress={event => {
                //         if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                //           handleOnRegister();
                //         }
                //       }}
                //       required
                //     />
                //     <label className='input-label'>
                //       Other University
                //     </label>
                //   </div>
                //   {errors.university_name && (
                //     <div className="asap-caption-1 text-apple-red">{errors.university_name[0]}</div>
                //   )}
                // </div>
              )}
            </div>
            <button
              className="button-sm button-primary"
              onClick={handleOnEditProfile}
              disabled={isLoading || !hasValidInputs()}>
            {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Save Changes"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProfile;